<template>
	<div style="min-width: 1300px;">
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">主题设置</div>
			<div class="mt20">
				<div class="bg-w pd30" style="min-height: 800px;">
					<div>
						  <a-alert message="请在右侧区域进行设置，左侧为展示区。" banner />
					</div>
					
					<div class="mt30">
						<div class="flex center">
							<div>
								<show-mobile :temps="temps"></show-mobile>
							</div>
							
							<div style="margin-left: 75px;">
								<div class="tempalte-operate">
									<div>
										<div class="flex alcenter space">
											<div class="ft14 ftwblod cl-balck">选择配色方案</div>
											
											<a-button @click="showTempDiv = true" type="danger">从模板库选择</a-button>
										</div>
										<div class="flex alcenter mt12">
											<span>
												<select-color title="主色调" v-model="temps.main_color"></select-color>
											</span>
											<span class="ml16">
												<select-color title="背景颜色" type="rgba" v-model="temps.home_bg"></select-color>
											</span>
										</div>
									</div>
									<div class="ft14 cl-main mt16">页面组件<span class="ft12 ml10 cl-notice">动态组件模版设置的时候只显示样式不调用真实数据</span></div>
									<div class="mt12">
										<plugins-edit v-model="temps.plugins"></plugins-edit>
									</div>
									<div class="ft14 cl-main mt16">底部导航<span class="ft12 ml10 cl-notice">底部“商城”和“我的”固定不动，可在中间添加修改导航标签，最多添加3个</span></div>
									<div class="mt12">
										<plugins-footers v-model="temps.footers"></plugins-footers>
									</div>
									
									<div class="mt30">
										<a-button type="primary" :loading="confirmLoading" @click="saveStyle()">保存</a-button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
		<select-template v-if="showTempDiv" @select="selectTemp" @closed="showTempDiv = false"></select-template>
		
	</div>
</template>

<script>
	import showMobile from './components/template/mobile.vue';
	import selectColor from './components/template/selectColor.vue';
	import  pluginsEdit from './components/template/plugins.vue';
	import  pluginsFooters from './components/template/footers.vue';
	import  selectTemplate from './components/template/selectTemplate.vue';
	export default{
		components:{
			showMobile,
			selectColor,
			pluginsEdit,
			pluginsFooters,
			selectTemplate
		},
		data(){
			return{
				showTempDiv:false,
				loading: false,
				confirmLoading:false,
				temps:{
					main_color:'#00BA26',
					home_bg:'#f7f8f9',
					plugins:[
						
						{
							name:'轮播图片',
							key:'banner',
							style:{
								mt:0,//距离顶部
								h:150,//高度
								r:8,
							},
							datas:[
								{pic:'', url:''},
								{pic:'', url:''}
							],
						},
						{
							name:'图标导航',
							key:'icon',
							style:{
								type:0,//样式1 样式2  样式3
								mt:16,//距离顶部
								isBg:1,
								space:10,
							},
							datas:[
								{pic:'', name:'图标',url:''},
								{pic:'', name:'图标',url:''},
								{pic:'', name:'图标',url:''},
								{pic:'', name:'图标',url:''},
								{pic:'', name:'图标',url:''},
								{pic:'', name:'图标',url:''},
								{pic:'', name:'图标',url:''},
							],
						},
						{
							name:'图片',
							key:'pics',
							style:{
								mt:16,//距离顶部
								h:90,//高度 个体最大高度
								space:10,//间距 当图片大于1的时候  最多4张图片
							},
							datas:[
								{pic:'', url:''},
								{pic:'', url:''},
							],
						},
						{
							name:'视频',
							key:'video',
							style:{
								mt:16,//距离顶部
								h:150,//高度 个体最大高度
								url:'',
							},
						},
						{
							name:'商品推荐',
							key:'product',
							style:{
								mt:16,//距离顶部
								icon:'',
								title:'商品推荐',
								num:8,
								category:0,
							},
							datas:[
								
							],
						},
					],
					footers:['appointment','buy'],
				},
			}
		},
		created() {
			this.loaddata()
		},
		methods:{
			selectTemp(style){
				this.temps = style;
				this.showTempDiv = false;
			},
			loaddata(){
				this.loading = true;
				this.$http.api('admin/getStyle').then(res=>{
					this.loading = false;
					if(res.style != null){
						this.temps = res.style;
					}
				}).catch(res=>{
					this.loading = false;
				});
			},
			
			saveStyle(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveStyle',{
					style:JSON.stringify(this.temps)
				}).then(res=>{
					this.$message.success('保存成功',1,()=>{
						this.confirmLoading=false;
						this.loaddata();
					})
				}).catch(res=>{
					this.confirmLoading=false;
				})
			},
			
		},
	}
</script>

<style>
	.tempalte-operate{
		width: 750px;
		height: 100%;
		background: #F9F9F9;
		border: 1px solid #F0F3F5;
		padding: 30px;
	}
</style>
