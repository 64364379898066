<template>
	<div style="height: auto; overflow: hidden;">
		<video :src="datas.style.url" controls="controls" :style="{height:datas.style.h + 'px',width:100 + '%'}">
		</video>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			
			
		},
		data(){
			return {
				
			}
		}
	}
</script>

<style>
	.showPicLeft{
		float: left;
	}
	.showPicRight{
		float:  right;
	}
</style>