<template>
	<div style="height: auto; overflow: hidden;">
		<div v-if="datas.datas.length == 1">
			<div v-if="datas.datas[0].pic == ''" style="background: #FFFFFF;" :style="{height:datas.style.h + 'px'}"></div>
			<img v-else :src="datas.datas[0].pic" style="width: 100%;" :style="{height:datas.style.h + 'px'}"/>
		</div>
		<div v-if="datas.datas.length == 3">
			<div v-for="(item,index) in datas.datas" :key="index" :class="{showPicLeft:index == 0,showPicRight:index !=0}"  :style="getThirdStyleBox[index]">
				<div v-if="item.pic == ''" :style="getThirdStyle[index]" style="background: #FFFFFF;width: 100%;"></div>
				<img  v-else :src="item.pic" :style="getThirdStyle[index]" style="width: 100%;" />
			</div>
		</div>
		<div v-if="datas.datas.length != 1 && datas.datas.length != 3" class="flex wrap space">
			<div v-for="(item,index) in datas.datas" :key="index"  :style="getStyle[index]">
				<div v-if="item.pic == ''" :style="{height:datas.style.h + 'px'}" style="background: #FFFFFF;width: 100%;"></div>
				<img  v-else :src="item.pic" :style="{height:datas.style.h + 'px'}" style="width: 100%;" />
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			getMt(){
				return "margin-top:"+this.datas.style.space+"px;";
			},
			getW(){
				let space = this.datas.style.space / 2;
				let w = "width:calc(50% - "+space+"px);";
				return w;
			},
			getStyle(){
				let arr = new Array;
				let h = "height:"+this.datas.style.h + 'px;';
				for(var a in this.datas.datas){
					if(a > 1){
						arr.push(h + this.getMt + this.getW);
					}else{
						arr.push(h +  this.getW);
					}
				}
				return arr;
			},
			getThirdStyleBox(){
				let arr = new Array;
				let styles = this.getThirdStyle;
				
				for(var a  in styles){
					arr.push(styles[a] + this.getW);
					if(a == 2){
						arr[a]+= this.getMt;
					}
				}
				return arr;
			},
			getThirdStyle(){
				let arr = new Array;
				let space = this.datas.style.space / 2;
				for(var a in this.datas.datas){
					let h = "";
					if(a == 0){
						h = "height:"+this.datas.style.h + 'px;';
					}else{
						h = "height:"+((this.datas.style.h/2) - space)+'px;'
					}
					arr.push(h);
				}
				
				return arr;
			}
		},
		data(){
			return {
				
			}
		}
	}
</script>

<style>
	.showPicLeft{
		float: left;
	}
	.showPicRight{
		float:  right;
	}
</style>