<template>
	<div>
		<div v-if="datas.style.type == 0 || datas.style.type == 3" class="show-icon-box" :class="{nobg:datas.style.isBg == 0,pd16_15:datas.style.isBg == 1}">
			<div v-for="(item,index) in getIcons" :key="index" :class="{mt12:index > 0}" class="flex alcenter space">
				<div v-for="(icon,i) in item" :key="i" class="icon-item"  :class="{big:datas.style.type == 3}">
					<div v-if="icon.pic != '' || icon.name != ''">
						<div  class="icon-item-img" :class="{big:datas.style.type == 3}">
							<img v-if="icon.pic != ''" :src="icon.pic" />
							<div v-else style="width: 100%; height: 100%; background: #f2f2f2;"></div>
						</div>
						<div class="mt8 ft12 cl-main ftw400 text-center">{{icon.name}}</div>
					</div>
				</div>
			</div>
		</div>
		
		<div v-if="datas.style.type == 1" class="flex wrap space">
			<div v-for="(icon,i) in datas.datas" :key="i" class="show-icon-box2 flex alcenter center" :style="getBoxWidth[i]">
				<img v-if="icon.pic != ''" style="width: 40px; height: 40px;"  :src="icon.pic" />
				<div v-else style="width: 40px; height: 40px; background: #f2f2f2;"></div>
				<div class="ml15 ft14 ftw600 cl-main">{{icon.name}}</div>
			</div>
		</div>
		
		<div v-if="datas.style.type == 2" class="flex wrap space">
			<div v-for="(icon,i) in datas.datas" :key="i" class="show-icon-box2" :style="getBoxWidth[i]">
				<div class="flex center">
					<img v-if="icon.pic != ''" style="width: 40px; height: 40px;"  :src="icon.pic" />
					<div v-else style="width: 40px; height: 40px; background: #f2f2f2;"></div>
				</div>
				<div class="ft14 ftw600 cl-main text-center mt8">{{icon.name}}</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		computed:{
			getBoxWidth(){
				let arr = new Array;
				for(var a in this.datas.datas){
					if(this.datas.style.space > 0){
						if(a > 1){
							arr.push('width:calc(50% - '+(this.datas.style.space/2)+'px);margin-top:'+this.datas.style.space + 'px;');
						}else{
							arr.push('width:calc(50% - '+(this.datas.style.space/2)+'px);');
						}
					}else{
						arr.push('width:50%;');
					}
				}
				return arr;
			},
			getIcons(){
				let arr = new Array;
				let max = this.datas.style.type == 3 ? 4:5;
				for(let i = 0;i<this.datas.datas.length;i+=max){
					let newarr = this.datas.datas.slice(i,i+max);
					let num = newarr.length;
					for(let a = 0; a < max-num;a++){
						newarr.push({pic:'', name:'',url:''});
					}
					arr.push(newarr);
					
				}
				return arr;
			}
		},
		data(){
			return {
				
			}
		}
	}
</script>

<style>
	.show-icon-box2{
		background: #FFFFFF;
		padding: 15px 25px 15px 25px;
		box-shadow: 0px 4px 16px -4px rgba(0, 0, 0, 0.04);
		border-radius: 8px;
		width: 50%;
	}
	.show-icon-box{
		width: 345px;
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
	}
	.show-icon-box.nobg{
		background: transparent;
	}
	.show-icon-box .icon-item{
		width: 40px;
	}
	.show-icon-box .icon-item.big{
		width: 48px;
	}
	.show-icon-box .icon-item-img{
		width: 40px;
		height: 40px;
	}
	.show-icon-box .icon-item-img.big{
		width: 48px;
		height: 48px;
	}
	.show-icon-box .icon-item-img img{
		width: 40px;
		height: 40px;
	}
	.show-icon-box .icon-item-img.big img{
		width: 48px;
		height: 48px;
	}
</style>