<template>
	<div>
		<a-carousel>
			<div v-for="(item,index) in datas.datas" :key="index" class="banner-item" :style="{height:datas.style.h + 'px',borderRadius:datas.style.r+'px'}">
				<img v-if="item.pic != '' " :src="item.pic"  :style="{height:datas.style.h + 'px',borderRadius:datas.style.r+'px'}"/>
			</div>
	    </a-carousel>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return {
				
			}
		}
	}
</script>

<style>
	.ant-carousel{
		font-size: 0;
	}
	.banner-item{
		background: #FFFFFF;
		overflow: hidden;
	}
	.banner-item img{
		width: 100%;
	}
</style>