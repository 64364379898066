export const footers = [
	{name:'商城',model:'category',icon:'icontabbar05'},
	{name:'相册',model:'look',icon:'icontabbar06'},
	{name:'购物车',model:'cart',icon:'icontabbar07'},
	{name:'预约',model:'appointment',icon:'icontabbar12'},
	{name:'买单',model:'buy',icon:'icontabbar02'},
	{name:'次卡',model:'oncecard',icon:'icontabbar13'},
	{name:'优惠券',model:'coupon',icon:'icontabbar15'},
];

//打开小程序 就是 miniapp:wx*** 电话就是tel:****
export const plugins = [
	{
		name:'搜索框',
		key:'search',
		style:{
			mt:0,//距离顶部
		}
	},
	{
		name:'轮播图片',
		key:'banner',
		style:{
			mt:16,//距离顶部
			h:150,//高度
			r:8,//圆角
		},
		datas:[
			{pic:'', url:''}
		],
	},
	{
		name:'图片',
		key:'pics',
		style:{
			mt:16,//距离顶部
			h:150,//高度 个体最大高度
			space:0,//间距 当图片大于1的时候  最多4张图片
		},
		datas:[
			{pic:'', url:''}
		],
	},
	{
		name:'视频',
		key:'video',
		style:{
			mt:16,//距离顶部
			h:150,//高度 个体最大高度
			url:'',
		},
	},
	{
		name:'图标导航',
		key:'icon',
		style:{
			type:0,//样式1 样式2  样式3
			mt:16,//距离顶部
			isBg:1,
			space:10,
		},
		datas:[
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
		],
	},
	{
		name:'项目推荐',
		key:'project',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'项目推荐',
			num:4,
			category:0,
		},
		datas:[
			
		],
	},
	{
		name:'商品推荐',
		key:'product',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'商品推荐',
		},
		datas:[
			
		],
	},
	{
		name:'优惠券',
		key:'coupon',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'优惠券',
		},
		datas:[
			
		],
	},


	{
		name:'店铺地址',
		key:'mendian',
		style:{
			mt:16,//距离顶部
		},
		datas:[
			
		],
	},
	{
		name:'服务相册',
		key:'look',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'服务相册',
		},
		datas:[
			
		],
	},
	{
		name:'办理次卡',
		key:'oncecard',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'办理次卡',
			num:1,
		},
		datas:[
			
		],
	},
];