<template>
	<div>
		<a-modal
		      title="选择模版"
			  width="1000px"
		      :visible="visible"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<a-spin :spinning="loading">
				
				<div v-if="datas.length > 0" class="flex wrap">
					<div v-for="(item,index) in  datas" :key="index" class="template-item">
						<img :src="item.photo" />
						<div class="name">
							{{item.name}}
						</div>
						<div class="main">
							<div class="flex center">
								<a-button @click="useTemp(index)">使用模版</a-button>
							</div>
						</div>
					</div>
				</div>
				
				<a-empty v-else />
				<a-pagination :current="page" :pageSize="limit" :total="total" @change="onChange" />
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				visible:false,
				page:1,
				limit:8,
				total:100,
				datas:[],
				loading:false,
			}
		},
		created(){
			this.visible = true;
			this.getLists();
		},
		methods:{
			onChange(e){
				this.page = e;
				this.getLists();
			},
			useTemp(index){
				let style = this.datas[index].style;
				this.$emit('select',style);
			},
			getLists(){
				if(this.loading == true) return;
				this.loading = true;
				this.$http.papi('platform/terminal/getAppTemplateList',{
					page:this.page,
					limit:this.limit,
				}).then(res=>{
					this.loading = false;
					this.total = res.total;
					this.datas = res.list;
				}).catch(res=>{
					this.loading = false;
				});
			},
			handleCancel(){
				this.visible = false;
				setTimeout(()=>{
					this.$emit('closed');
				},300)
			}
		}
	}
</script>

<style>
	.template-item{
		width: 220px;
		height: 360px;
		border:1px solid #EBEDF5;
		border-radius: 8px;
		overflow: hidden;
		margin-right: 20px;
		margin-bottom: 20px;
		position: relative;
	}
	.template-item:nth-child(4n){
		margin-right: 0px;
	}
	.template-item .main{
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		display: none;
		background: rgba(0,0,0,.6);
		padding-top: 120px;
	}
	.template-item img{
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	
	.template-item:hover .main{
		display: block;
	}
	.template-item .name{
		position: absolute;
		left: 0;
		bottom: 0;
		height: 30px;
		background: rgba(0,0,0,0.5);
		color: #FFFFFF;
		font-size: 14px;
		text-align: center;
		line-height: 30px;
		width: 100%;
	}
</style>