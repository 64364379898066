<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="900px"
		      title="设置组件"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="ft14 cl-main">基本设置</div>
			<div class="mt12">
				   视频链接 <a-input  v-model="mydatas.style.url"  /> (暂只支持mp4,3gp,m3u8)
			</div>
			<div class="mt12">
				   距离上组件 <a-input-number id="inputNumber" v-model="mydatas.style.mt" :min="0" :max="50" />px
			</div>
			<div class="mt12">
				   视频高度 <a-input-number id="inputNumber" v-model="mydatas.style.h" :min="0" :max="500" />px
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default{
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			},
		}
	}
</script>

<style>
	.pics-edit-table{
		background: #F5F6F8;
		width: 100%;
	}
	.pics-edit-table thead th{
		padding: 10px 20px;
		font-size: 14px;
		color:#666666;
	}
	.pics-edit-table tbody td{
		padding: 10px 20px;
	}
	.pics-edit-table tbody tr{
		border-top: 1px solid #E4E6ED;
	}
</style>