<template>
	<div class="search-show-box plr15 flex alcenter">
		<i class="iconfont iconsearch_gray cl-notice ft14"></i>
		<span class="ml10 ft14 cl-notice ftw500">搜索商品</span>
	</div>
</template>

<script>
	export default{
		props:{
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		data(){
			return {
				
			}
		}
	}
</script>

<style>
	.search-show-box{
		width: 345px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 8px;
	}
</style>